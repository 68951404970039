import { render, staticRenderFns } from "./EditorField.vue?vue&type=template&id=2b7690e4&"
import script from "./EditorField.vue?vue&type=script&lang=js&"
export * from "./EditorField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCardSubtitle,VSheet})
