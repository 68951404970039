<template>
  <div>
    <v-sheet v-if="field.entity === entity && field.fieldType === 'textField'" class="my-3 pa-3" color="tertiary">
      <LeaTextField
          :formScope="formScope"
          :fieldName="field.fieldName"
          :label="field.name"
          :value="model"
          @input="$emit('input', $event)"
          @modified="$emit('modified', $event)"
          :required="true"
      />
    </v-sheet>
    <v-sheet v-else-if="field.entity === entity && field.fieldType === 'textArea'" class="my-3 pa-3" color="tertiary">
      <LeaTextArea
          :formScope="formScope"
          :fieldName="field.fieldName"
          :label="field.name"
          :value="model"
          @input="$emit('input', $event)"
          @modified="$emit('modified', $event)"
          :required="true"
      />
    </v-sheet>
    <v-sheet v-else-if="field.entity === entity && field.fieldType === 'select'" class="my-3 pa-3" color="tertiary">
      <LeaSelect
        :formScope="formScope"
        :fieldName="field.fieldName"
        :label="field.name"
        :value="model"
        @input="$emit('input', $event)"
        @modified="$emit('modified', $event)"
        :items="items"
        :multiple="field.multiple"
        :item-value="field.itemValue"
        :item-text="field.itemText"
        :chips="field.multiple"
        :deletable-chips="field.multiple"
        :required="true"
        />
    </v-sheet>
    <v-sheet v-else-if="field.entity === entity && (field.fieldType === 'autocomplete' || field.fieldType === 'combobox')" class="my-3 pa-3" color="tertiary">
      <LeaAutocomplete
        :formScope="formScope"
        :fieldName="field.fieldName"
        :label="field.name"
        :value="model"
        @input="$emit('input', $event)"
        @modified="$emit('modified', $event)"
        :items="items"
        :multiple="field.multiple"
        :item-value="function(e) {
            return e[field.itemValue]
        }"
        :item-text="field.itemText"
        :chips="field.multiple"
        :deletable-chips="field.multiple"
        :required="true"
        />
    </v-sheet>
    <v-sheet v-else-if="field.entity === entity && field.fieldType === 'switch'" class="my-3 pa-3" color="tertiary">
    <LeaSwitch
        :formScope="formScope"
        :fieldName="field.fieldName"
        :label="field.name"
        :value="model"
        @input="$emit('input', $event)"
        @modified="$emit('modified', $event)"
        :label-on="$t('yes')"
        :label-off="$t('no')"
        />
    </v-sheet>
    <v-sheet v-else-if="field.entity === entity && field.fieldType === 'date'" class="my-3 pa-3" color="tertiary">
    <LeaDate
        :formScope="formScope"
        :fieldName="field.fieldName"
        :label="field.name"
        :value="model"
        @input="$emit('input', $event)"
        @modified="$emit('modified', $event)"
        />
    </v-sheet>
    <v-sheet v-else-if="field.entity === entity && field.fieldType === 'assignment'" class="my-3 mr-6">
      <v-card-subtitle v-text="field.name" />
      <ContactAssignment
          :formScope="formScope"
          :fieldName="field.fieldName"
          :fixed-category="field.category"
          :label="field.name"
          :comp-id="field.fieldName + '_' + field.category"
          v-model="contactAssignmentEditorModel"
          @input="$emit('input', $event)"
          @modified="$emit('modified', $event)"
          />
    </v-sheet>
  </div>
</template>

<script>
import LeaTextField from '@/components/Input/LeaTextField';
import LeaTextArea from '@/components/Input/LeaTextArea';
import LeaSelect from '@/components/Input/LeaSelect';
import LeaAutocomplete from '@/components/Input/LeaAutocomplete';
import LeaSwitch from '@/components/Input/LeaSwitch';
import LeaDate from '@/components/Input/LeaDate';
import ContactAssignment from '@/components/vvt/ProcessEditor/Basics/ContactAssignment';
export default {
  components: {
    LeaTextField,
    LeaTextArea,
    LeaSelect,
    LeaAutocomplete,
    LeaSwitch,
    LeaDate,
    ContactAssignment
  },
  props: {
    value: {
        type: [Number, String, Array, Boolean],
        default: null,
    },
    field: {
      type: Object,
      default: () => {}
    },
    sameCompanyId: {
        type: Number,
        default: null
    },
  },
  data () {
    return {
      entity: 'App\\Entity\\ProcessingActivity\\ProcessingActivity',
      formScope: 'bulkEdit',
      items: []
    }
  },
  computed: {
    model: {
        get() {
          return this.value;
        },
        set(val) {
            this.$emit('input', val)
        },
    },
    contactAssignmentEditorModel: {
        get() {
          // merge all types of persons
          let contactItems = this.value;
          contactItems = contactItems.map(x => {
            const prefix = x.title ? x.title + ' ' : '';
            x.fullName = `${prefix}${x.firstName} ${x.lastName}`;
            return x;
          });
          return contactItems;
        },
        set(val) {
            this.$emit('input', val)
        },
    },
  },
  methods: {
    hasSameCompanyRestriction () {
      return this.$objectPropertyExists(this.field, 'bulkEditRestriction') && 
            Array.isArray(this.field.bulkEditRestriction) && 
            this.field.bulkEditRestriction.includes('onlyRecordsForSameCompany') && 
            this.sameCompanyId !== null;
    },
    getStoredItems () {
      if(typeof this.$store.getters[this.field.getItems] === 'object') {
        return this.$store.getters[this.field.getItems];
      } else if(typeof this.$store.getters[this.field.getItems] === 'function') {
        if(this.hasSameCompanyRestriction()) {
          return this.$store.getters[this.field.getItems](this.sameCompanyId);
        }
      }
      return [];
    },
    generateItemOptions () {
      if(Object.prototype.hasOwnProperty.call(this.field, 'items')) {
        this.items = this.field.items.map(this.formatItem);
        return this.items;
      }
      if(Object.prototype.hasOwnProperty.call(this.field, 'getItems')) {
        let items = this.getStoredItems();
        this.$nextTick(() => {
          this.items = items;
          return this.items;
        })
      }
      this.items = [];
      return [];
    },
    fetchItems() {
      if(Object.prototype.hasOwnProperty.call(this.field, 'fetchItems')) {
        let payload = {};
        if(this.hasSameCompanyRestriction()) {
          payload.id = this.sameCompanyId;
        }
        this.$store.dispatch(this.field.fetchItems, payload).then(() => {
          this.generateItemOptions();
        });
      } else {
        this.generateItemOptions();
      }
    },
    formatItem(item) {
      if (this.$te(item[this.field.itemText])) {
        item[this.field.itemText] =  this.$t(item[this.field.itemText]);
      }
      return item;
    }
  },
  mounted () {
    this.fetchItems();
    this.model = this.field.defaultValue;
  },
  watch: {
    sameCompanyId(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.fetchItems();
      }
    }
  }
}
</script>